(function () {
	'use strict';

	const Cliente = {
		exec(cmd, data) {
			return new Promise((resolve, reject) => {
				$.ajax({
					url: `minha-conta/${cmd}`,
					type: "post",
					dataType: "json",
					data
				})
					.then(resp => {
						if (!resp.success) {
							reject(new Error(resp.msg));
						} else {
							resolve(resp);
						}

						if ("undefined" != typeof resp.redirect_url) {
							location.href = resp.redirect_url;
						}
					})
					.catch(err => {
						reject(
							new Error("Não foi possível completar a requisição.")
						);
					});
			});
		},
		login(login, senha) {
			return this.exec("do-login", { login, senha });
		},
		salvar(data) {
			return this.exec("salvar", data);
		},
		recuperarSenha(email) {
			return this.exec("send-recuperar", { email });
		}
	};

	$("body").on("submit", "[name=formLogin]", function() {
		const $form = $(this);
		if ($form.data("enviando")) {
			return false;
		}

		if (!$form.find(".required").validate()) {
			return false;
		}

		$form.data('enviando', true);
		$.fancybox.showLoading();

		const $login = $form.find("[name=login]");
		const login = $login.val();

		const $senha = $form.find("[name=senha]");
		const senha = $senha.val();

		Cliente.login(login, senha)
			.then(resp => {
				if ("undefined" == typeof resp.redirect_url) {
					location.reload(true);
				}
			})
			.catch(err => {
				alert(err.message);
				$.fancybox.hideLoading();
				$form.data('enviando', false);
			})
			.then(() => {});

		return false;
	});


	$('body').on('submit', '[name=formCadastro]', function() {
		const $form = $(this);
		if ($form.data("enviando")) {
			return false;
		}

		if (!$form.find(".required").validate()) {
			return false;
		}

		$form.data('enviando', true);
		$.fancybox.showLoading();

		const data = $form.serializeArray();

		Cliente.salvar(data)
			.then(resp => {
				if ("undefined" == typeof resp.redirect_url) {
					location.reload(true);
				}
			})
			.catch(err => {
				alert(err.message);
				$.fancybox.hideLoading();
				$form.data('enviando', false);
			})
			.then(() => {});

		return false;
	});

	$('body').on('submit', '[name=formRecuperar]', function() {
		const $form = $(this);

		if ($form.data('enviando')) {
			return false;
		}

		if (!$form.find('.required').validate()) {
			return false;
		}

		const $email = $form.find('[name=email]');
		const email = $email.val();

		$form.data('enviando', true);
		$.fancybox.showLoading();

		Cliente.recuperarSenha(email)
			.then(resp => {
				alert(resp.msg);
				$email.val('');
			})
			.catch(err => {
				alert(err.message);
			})
			.then(() => {
				$.fancybox.hideLoading();
				$form.data('enviando', false);
			});

		return false;
	});

	$('body').on('keyup', '[name=cep]', function() {
		const $cep = $(this);
		const $form = $cep.closest('form');
		const cep = $cep.val().replace(/\D/igm, '');
		if (8 != cep.length) {
			return;
		}

		if ($cep.data('enviando')) {
			return;
		}

		$cep.data('enviando', true);
		$.fancybox.showLoading();

		$.ajax({
			url: `https://viacep.com.br/ws/${cep}/json`,
			type: 'get',
			dataType: 'json'
		}).then(resp => {
			resp.cidade = resp.localidade;
			delete resp.localidade;
			delete resp.unidade;
			delete resp.ibge;
			delete resp.gia;
			$.each(resp, (name, val) => {
				$form.find(`[name=${name}]`).val(val);
			});
		}).catch(err => {
			console.log(err);
		}).then(() => {
			$.fancybox.hideLoading();
			$cep.data('enviando', false);
		});
	});

}());
